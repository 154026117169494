import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// COMPONENTS
import { SelectEventComponent } from "../select-event/select-event.component";
import { SelectFriendsComponent } from "../select-friends/select-friends.component";
import { SelectSeatComponent } from "../select-seat/select-seat.component";
import { CheckoutComponent } from "../checkout/checkout.component";
import { SummaryComponent } from "../summary/summary.component";

// ROUTES
const routes: Routes = [
    {
      path: '', 
      component: SelectEventComponent, 
      title: 'Select Event',
      data: {
        title: 'Buy Tickets',
        type:  'event'
      }
    },
    {
      path: 'select-friends', 
      component: SelectFriendsComponent, 
      title: 'Select Friends'
    },
    {
      path: 'select-seat',
      component: SelectSeatComponent,
      title: 'Select seat',
      data: {type: 'event'}
    }, 
    {
      path: 'checkout',
      component: CheckoutComponent, 
      title: 'Checkout'
    },
    {
      path: 'summary/:status',
      component: SummaryComponent,
      title: 'Summary | Buy Tickets'
    }
  ]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BuyTicketsRoutingModule {}