import { DestroyRef,  Injectable } from '@angular/core';
import { AvailabilityService } from '../../availability.service';
import { PriceScaleCollection } from 'src/app/shared/models/availabilty/section.model';
import { Event } from 'src/app/shared/models/event.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { SeatCollection } from 'src/app/shared/models/availabilty/seat.model';
import { ModalService } from '../../modal.service';

@Injectable({
  providedIn: 'root'
})
export class EventService extends AvailabilityService<Event, PriceScaleCollection | SeatCollection> {
  
  constructor(
    destroy:  DestroyRef,
    http:     HttpClient,
    route:    ActivatedRoute,
    router:   Router,
    modal:    ModalService
  ) {
    super(destroy, http, route, router, modal)
  }

  public isSelectSeat:              boolean = false;

  protected override routeParams:   string  = 'event';

  protected override returnRoute:   string  = 'buy-tickets';
  
  protected override get rootUrl(): string {
    return '/events/'
  }

  public setSelectSeatStatus(status: boolean): void {
    this.isSelectSeat = status;
  }

  public override refresh(additionalId?: string | undefined): void {
    
    const entity: Event = this.entitySelected() as Event;
    
    this.resetAvailability();

    additionalId ? this.getAvailabiltyHandler(entity.id, additionalId) : this.getAvailabiltyHandler(entity.id);
  }

  public override getSectionAvailability(event: string|number, id: string|number): Observable<SeatCollection> {
    
    let url = this.rootUrl + event + this.endpoint + id;
   
    return this.http.get<SeatCollection>(url, {params: this.getAvailabilityParams()}).pipe(
      map((data: SeatCollection) => {
      
      const formattedSeatCollection: SeatCollection = {};

      Object.entries(data).forEach(([key, value]) => {
        const finalKey = `${id}-${key}`;
        formattedSeatCollection[finalKey] = value;
      });

      return formattedSeatCollection;
    
    }))
    
  }

  protected override getAvailabilityParams(): HttpParams {
    if(this.entitySelected() as Event){
      
      let params: any = {};
      
      //Si es Membership o NO inventoried
      if(!this.entitySelected().inventoried){
        params['force_scale_summary'] = true;
      }else{
        // Si es Select a Seat
        if(this.entitySelected().select_a_seat || this.isSelectSeat){
          params['purchase_flow'] = 'seat_selection';
        }else{
          // Si no, entonces es Price Scale
          params['purchase_flow'] = 'price_scale';
        }
      }

      return new HttpParams({fromObject: params});

    }else{

      return new HttpParams({fromObject:{}});
      
    }
  }
    
}
