import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// COMPONENTS
import { BuyTicketsComponent } from './buy-tickets.component';

// ROUTING
import { BuyTicketsRoutingModule } from './buy-tickets-routing.module';

// MODULES
import { SelectEventModule } from '../select-event/select-event.module';
import { SelectFriendsModule } from '../select-friends/select-friends.module';
import { SelectSeatModule } from '../select-seat/select-seat.module';
import { CheckoutModule } from '../checkout/checkout.module';
import { FooterModule } from 'src/app/shared/components/globals/footer/footer.module';

// SERVICES
import { AvailabilityService } from 'src/app/core/services/availability.service';
import { EventService } from 'src/app/core/services/override/availability/event.service';
import { CheckoutService } from 'src/app/core/services/checkout.service';
import { SaleTransactionService } from 'src/app/core/services/override/checkout/sale-transaction.service';
import { SummaryModule } from '../summary/summary.module';


@NgModule({
  declarations: [
    BuyTicketsComponent,
  ],
  imports: [
    CommonModule,
    SelectEventModule,
    SelectSeatModule,
    SelectFriendsModule,
    CheckoutModule,
    FooterModule,
    SummaryModule,
    BuyTicketsRoutingModule
  ],
  providers: [
    // Availability Service
    { 
      provide:  AvailabilityService, 
      useClass: EventService
    },
    // Checkout Service
    {
      provide:  CheckoutService, 
      useClass: SaleTransactionService
    }
  ]
})

export class BuyTicketsModule {}

